import React from 'react';
import { Table, Container, Button, Alert } from 'react-bootstrap';
import '../../styles/Abonnements/ReservationsTennis.css';

const ReservationsTennis = () => {
  return (
    <Container>
      <h1 className="text-center my-4">Réservations – tarif horaire</h1>
      <Table bordered hover className="reservations-table">
        <thead>
          <tr>
            <th></th>
            <th>9h-17h</th>
            <th>17h-22h</th>
            <th>Après 22h</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Du lundi au vendredi</td>
            <td>15€</td>
            <td>20€</td>
            <td>15€</td>
          </tr>
          <tr>
            <td>Week-end</td>
            <td>20€</td>
            <td>20€</td>
            <td>15€</td>
          </tr>
        </tbody>
      </Table>

      <div className="reservation-options text-center my-4">
        <Button variant="primary" href="/reservationtennis" target="_blank" className="mx-2">
          Réservation
        </Button>
      </div>
    </Container>
  );
};

export default ReservationsTennis;
