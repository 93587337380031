import React from 'react';
import { Button, Container } from 'react-bootstrap';
import videoBanner from '../../images/banner.mp4';
import '../../styles/Homepage/Carousel.css';

const CarouselComponent = () => {
  return (
    <div className="carousel-container">
      <Container fluid>
        <div className="carousel-title">
          <h1>Tennis Padel Club Neupré</h1>
        </div>
        <div className="video-banner">
          <video className="d-block w-100" autoPlay muted loop>
            <source src={videoBanner} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="fixed-buttons text-center">
          <Button variant="primary" href="/reservationtennis" className="carousel-button">Réserver un terrain de tennis</Button>
          <Button variant="primary" href="/reservationpadel" className="carousel-button">Réserver un terrain de padel</Button>
        </div>
      </Container>
    </div>
  );
};

export default CarouselComponent;
