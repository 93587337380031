import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import '../../styles/Homepage/PartnersCarousel.css'; // Assurez-vous de créer ce fichier pour les styles personnalisés

import partner1 from '../../images/partners/avantagetennis.png'
import partner2 from '../../images/partners/cosy.png'
import partner3 from '../../images/partners/neupre.png'
import partner4 from '../../images/partners/profix.png'
import partner5 from '../../images/partners/raygeo.png'
import partner6 from '../../images/partners/projetcobat.png'
import partner7 from '../../images/partners/gribomont.png'


const PartnersCarousel = () => {
  return (
    <Container className="partners-carousel">
      <h2 className="text-center my-4">Nos partenaires</h2>
      <Row className="justify-content-center">
        <Col md={8}>
          <Carousel controls={false} indicators={false} interval={3000} pause={false}>
            <Carousel.Item>
              <Row className="justify-content-center">
                <Col md={3}><img src={partner1} alt="Partner 1" className="d-block w-100" /></Col>
                <Col md={3}><img src={partner2} alt="Partner 2" className="d-block w-100" /></Col>
                <Col md={3}><img src={partner3} alt="Partner 3" className="d-block w-100" /></Col>
                <Col md={3}><img src={partner4} alt="Partner 4" className="d-block w-100" /></Col>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row className="justify-content-center">
                <Col md={3}><img src={partner5} alt="Partner 5" className="d-block w-100" /></Col>
                <Col md={3}><img src={partner6} alt="Partner 1" className="d-block w-100" /></Col>
                <Col md={3}><img src={partner7} alt="Partner 2" className="d-block w-100" /></Col>
                <Col md={3}><img src={partner3} alt="Partner 3" className="d-block w-100" /></Col>
              </Row>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default PartnersCarousel;
