import React from 'react';
import { Table, Container, Button } from 'react-bootstrap';
import '../../styles/Abonnements/AbonnementsPadel.css';

const AbonnementsPadel = () => {
  return (
    <Container>
      <h1 className="text-center my-4">Cotisations padel extérieur – 01/01/2025 – 31/12/2025</h1>

      <div className="abonnement-section text-center my-4">
        <Table bordered hover className="cotisations-table">
          <thead>
            <tr>
              <th>Type de Cotisation</th>
              <th>Tarif</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Abonnement annuel (accès aux terrains extérieurs)</td>
              <td>180€ (licence et participation aux interclubs comprises) <br />(175€ pour les habitants de Neupré)</td>
            </tr>
            <tr>
              <td>Licence AFP ou AFT (sans accès aux terrains)</td>
              <td>35€</td>
            </tr>
            <tr>
              <td>Formule IC (mars à juin, pas d'accès en dehors des IC)</td>
              <td>60€ (hors licence)</td>
            </tr>
            <tr>
              <td>Formule Inter-équipes (pas d'accès en dehors des IC)</td>
              <td>40€ (hors licence)</td>
            </tr>
            <tr>
              <td>Duo Interclubs (pas d'accès en dehors des IC)</td>
              <td>90€ (hors licence)</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="inscription-details">
            <h4>Comment vous inscrire ?</h4>
            <ul>
              <li>
                Renvoyer la fiche formulaire d’inscription saison 2025 complétée par mail à l’adresse suivante : 
                <a href="mailto:info@neupretennispadel.be"> info@neupretennispadel.be</a>
              </li>
              <li>
                Régler le montant de la cotisation par virement bancaire
                <ul>
                  <li>Numéro de compte : BE39 0011 3464 0019</li>
                  <li>Destinataire : TC NEUPRE</li>
                  <li>Communication libre : NOM + PRENOM (si plusieurs personnes, indiquez tous les membres qui en bénéficient)</li>
                </ul>
              </li>
              <li>
                Attention, TOUS les joueurs et joueuses doivent compléter <a href="https://forms.office.com/r/zZdhJhHUCz">le formulaire d’inscription suivant, </a> 
                en ce compris ceux qui ont déjà payé leur(s) cotisation(s), ce document est nécessaire pour vous activer dans Webclub
              </li>
              <br />
        <strong>Le paiement doit être effectué avant le 31/12/2024</strong>
            </ul>
          </div>

      <div className="pass-visiteur text-center my-4">
        <h2>Pass visiteur</h2>
        <p>Pour les terrains extérieurs, une cotisation annuelle est possible.</p>
        <p>Il est également possible de prendre un pass visiteur pour 30€ via Balle Jaune.</p>
        <Button variant="primary" href="https://ballejaune.com/club/Tc-Neupre" target="_blank">
          Réserver via Balle Jaune
        </Button>
      </div>

      <div className="terrains-interieurs text-center my-4">
        <h2>Terrains intérieurs</h2>
        <p>Pour les terrains intérieurs, il faut passer par Sport Finder.</p>
        <p>Le tarif est de 36€ à 48€ en fonction du créneau réservé pour 1h30.</p>
        <Button variant="primary" href="https://www.sport-finder.com/fr/center/tc-neupre-padel-club" target="_blank">
          Réserver via Sport Finder
        </Button>
      </div>
    </Container>
  );
};

export default AbonnementsPadel;
